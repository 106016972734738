/* eslint-disable */
import { message } from 'antd'
import {useEffect, useRef} from 'react'
import {Navigate, Outlet, Route, Routes, useLocation, useNavigate} from 'react-router-dom'
import { AppStatusType, selectAppStatus, selectAppMessage, selectUIPermissions, GetUIPermissionsThunk } from '../store/appStatusReducer'
import { SignOutThunk, selectIsLoggedIn, selectUserData } from '../store/currentUserReducer'
import './App.css'
import { useAppDispatch, useAppSelector } from './hooks'
import routes from './routes'
import Header from '../components/Header/Header'
import { LicenseInfo } from '@mui/x-license-pro'
import DreamInterpreter from '../components/DreamInterpreter/DreamInterpreter'
import {GetLastActiveChatThunk, selectCurrentChat, StopChatThunk} from '../store/dreamInterpreterReducer'
import { GetUserBillingSettingsThunk } from '../store/userReducer'
import { useTranslation } from 'react-i18next'

function App() {
  LicenseInfo.setLicenseKey('9d97c43dbb954ce971f2fbc4846f34b9Tz04MTkxMSxFPTE3MzY0OTYzNjUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=')
  const { t } = useTranslation(['app'])
  const dispatch = useAppDispatch()
  const appStatus = useAppSelector(selectAppStatus)
  const appMessage = useAppSelector(selectAppMessage)
  const uiPermissions = useAppSelector(selectUIPermissions)
  const userData = useAppSelector(selectUserData)
  const currentChat = useAppSelector(selectCurrentChat)
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  const navigate = useNavigate()
  const location = useLocation()
  const currentPath = location.pathname
  const hasConfirmedRef = useRef(false)

  useEffect(() => {
    if(!!appMessage?.length) {
      appStatus === AppStatusType.failed && message.error(appMessage, 2)
      appStatus === AppStatusType.succeeded && message.success(appMessage, 2)
      appStatus === AppStatusType.info && message.info(appMessage, 2)
    } else if (appStatus === AppStatusType.fatal) {
      dispatch(SignOutThunk())
    }
  }, [dispatch, appStatus, appMessage])

  useEffect(() => {
    if (uiPermissions === null && userData?.user_id) {
      dispatch(GetUIPermissionsThunk())
    }
  }, [dispatch, uiPermissions, userData])

  useEffect(() => {
    if (userData?.user_id && currentChat === undefined) {
      dispatch(GetUserBillingSettingsThunk({
        userId: userData.user_id,
        isCurrentUser: true
      }))
      dispatch(GetLastActiveChatThunk())
          .then((res) => {
            if(res.payload !== null){
              // @ts-ignore
              dispatch(StopChatThunk(res!.payload!.id))
            }
          })
    }
  }, [dispatch, currentChat, userData])

  useEffect(() => {
    if(isLoggedIn && currentPath !== '/login/create-password'){
      navigate('/patients')
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (currentChat !== undefined && currentChat !== null) {
      const handleBeforeUnload = (event: BeforeUnloadEvent) => {
        const message = t('warnings.leaving')
        event.preventDefault()
        event.returnValue = message
        const onUnload = () => {
          if (!hasConfirmedRef.current) {
            hasConfirmedRef.current = true
            dispatch(StopChatThunk(currentChat?.id!))
          }
        }
        window.addEventListener('unload', onUnload)
        return () => {
          window.removeEventListener('unload', onUnload)
        }
      }
      window.addEventListener('beforeunload', handleBeforeUnload)
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload)
      }
    }
  }, [dispatch, currentChat])

  return (
    <div className='app'>
      <Routes>
        {routes.map((route) => (
          route.auth ? (
            <Route key={route.path} path={route.path} element={<PrivateRoute/>}>
              <Route path={route.path} element={<route.component {...route.props}/>}/>
            </Route>
          ) : (
            <Route key={route.path} element={<route.component {...route.props}/>} path={route.path}/>
          )
        ))}
      </Routes>
    </div>
  )
}

const PrivateRoute = () => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn)
  return isLoggedIn ? (
    <div style={{position: 'relative'}}>
      <Header />
      <div className='appContent'>
        <Outlet/>
      </div>
      <DreamInterpreter />
    </div>
  ) : (
    <Navigate to='/' />
  )
}

export default App
