import { Button, DatePicker, Spin, Tabs } from 'antd'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'
import classes from './SessionForm.module.css'
import GoBackLink from '../../common/GoBackLink/GoBackLink'
import InstanceTab from './InstanceTab/InstanceTab'
import NotesTab from './NotesTab/NotesTab'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { GetPersonByIdThunk, selectSelectedPersonData, setPersonDetailsActiveTabKey } from '../../../store/personReducer'
import { CreateSessionThunk, EditSessionThunk, GetSessionsBySessionIdThunk, selectSessionData, setSessionList, setSessionData as setSessionStateData } from '../../../store/sessionReducer'
import { NewSessionDataType, SessionFormDataType } from '../../../types/sessionTypes'
import PopoverConfirm from '../../common/PopoverConfirm/PopoverConfirm'
import ConfirmationModal from '../../common/ConfirmationModal/ConfirmationModal'

const SessionForm: React.FC<{isEditing: boolean}> = ({isEditing}) => {
  dayjs.extend(utc)
  const { t } = useTranslation(['app'])
  const getPersonId = () => {
    return +pathname.substring(
      pathname.indexOf('/patients/') + '/patients/'.length, 
      pathname.lastIndexOf('/sessions/')
    )
  }
  const getSessionId = () => {
    return +pathname.split('sessions/edit/')[1]
  }

  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const selectedSessionData = useAppSelector(selectSessionData)

  const [activeTabKey, setActiveTabKey] = useState('1')
  const [isLoading, setIsLoading] = useState(isEditing)
  const [sessionData, setSessionData] = useState<SessionFormDataType>({
    mainData: {
      rec_date: dayjs().format('YYYY-MM-DDTHH:mm:ss[Z]'),
      person_id: getPersonId(),
      extra_data: '',
    },
    sessionData: []
  })
  const [hasDataChanged, setHasDataChanged] = useState(false)
  const [isBackLinkConfirmModalShown, setIsBackLinkConfirmModalShown] = useState(false)

  useEffect(() => {
    const personId = getPersonId()
    if (!selectedPersonData?.id || personId !== selectedPersonData?.id) {
      dispatch(GetPersonByIdThunk(personId))
    }
    // eslint-disable-next-line
  }, [dispatch, pathname, selectedPersonData])

  useEffect(() => {
    if (isEditing) {
      dispatch(GetSessionsBySessionIdThunk(getSessionId()))
        .then(() => setIsLoading(false))
    }
    // eslint-disable-next-line
  }, [dispatch, isEditing])

  useEffect(() => {
    if (isEditing && selectedSessionData?.id) {
      setSessionData({
        mainData: {
          rec_date: selectedSessionData.rec_date,
          person_id: getPersonId(),
          extra_data: selectedSessionData?.extra_data,
        },
        sessionData: selectedSessionData?.person_data_rec_list?.map(rec => ({
          instance_id: rec.instance.id,
          topic_id: rec.topic.id,
          subtopics: rec.subtopics.map(subtopic => ({
            subtopic_id: subtopic?.id,
            subtopic_name: subtopic?.name,
            note: subtopic?.note
          }))
        }))
      })
    }
    // eslint-disable-next-line
  }, [dispatch, isEditing, selectedSessionData])

  const saveData = async() => {
    const instanceItems = sessionData.sessionData
      .filter(topicItem => !!topicItem.subtopics?.length)
      .map(topicItem => (
        topicItem.subtopics?.map(subtopic => ({
          instance_id: topicItem.instance_id,
          topic_id: topicItem.topic_id,
          subtopic_id: subtopic.subtopic_id,
          note: subtopic.note
        }))
      ))
      .flat()

    if (isEditing) {
      return dispatch(EditSessionThunk({
        sessionId: getSessionId(),
        mainData: sessionData.mainData,
        sessionData: instanceItems as NewSessionDataType[]
      }))
    } else {
      return dispatch(CreateSessionThunk({
        mainData: sessionData.mainData,
        sessionData: instanceItems as NewSessionDataType[]
      }))
    }
  }

  const saveSession = () => {
    dispatch(setSessionList(null))
    return saveData()
      .then(() => {
        dispatch(setPersonDetailsActiveTabKey('sessions'))
        navigate(`/patients/edit/${getPersonId()}`)
      })
  }

  const sessionDataTabs = [
    {
      key: '1',
      label: 'Instance',
      children: <InstanceTab
        isEditing={isEditing}
        setSessionData={(data: SessionFormDataType) => setSessionData(data)}
        sessionData={sessionData}
        setHasDataChanged={(hasChanged: boolean) => setHasDataChanged(hasChanged)}
      />,
    },
    {
      key: '2',
      label: 'Notes',
      children: <NotesTab
        isEditing={isEditing}
        setSessionData={(data: SessionFormDataType) => setSessionData(data)}
        sessionData={sessionData}
        setHasDataChanged={(hasChanged: boolean) => setHasDataChanged(hasChanged)}
      />,
    },
  ]

  if (isLoading) {
    return <Spin style={{width: '100%'}} />
  }

  return (
    <div className={classes.wrapper}>
      <GoBackLink
        onClick={() => {
          dispatch(setSessionStateData(null))
          dispatch(setPersonDetailsActiveTabKey('sessions'))
          navigate(`/patients/edit/${getPersonId()}`)
        }}
        text='To list of sessions'
        style={{marginBottom: '10px'}}
        showConfirmModal={hasDataChanged && sessionData?.sessionData?.length ? () => setIsBackLinkConfirmModalShown(true) : undefined}
      />
      <h1 className='pageTitle'>
        {isEditing ? 'Edit' : 'New'} session ({selectedPersonData?.name})
      </h1>
      <div className='pageDescription'>
        <DatePicker
          value={dayjs.utc(sessionData?.mainData?.rec_date)}
          onChange={val => setSessionData({...sessionData, mainData: {...sessionData.mainData, rec_date: dayjs(val).format('YYYY-MM-DDTHH:mm:ss[Z]')}})}
          allowClear={false}
          showTime={{ format: 'HH:mm' }}
          format={'YYYY-MM-DD HH:mm'}
        />
      </div>
      <Tabs
        items={sessionDataTabs}
        activeKey={activeTabKey}
        onChange={(key) => setActiveTabKey(key)}
      />
      {activeTabKey === '1' &&
        <div className={`${classes.actionButtons} actionButtons`}>
          {!sessionData?.sessionData?.length &&
            <div className={classes.warning}>
              Select at least one topic
            </div>
          }
          <PopoverConfirm
            title={t('warnings.cancelation', {ns: 'app'})}
            onConfirm={() => navigate(`/patients/edit/${getPersonId()}`)}
            hasChanges={hasDataChanged && !!sessionData?.sessionData?.length}
          >
            <Button>
              Cancel
            </Button>
          </PopoverConfirm>
          {hasDataChanged && !!sessionData?.sessionData?.length &&
            <Button
              type='primary'
              // onClick={() => setIsSaveModalOpen(true)}
              onClick={() => saveSession()}
              disabled={!sessionData.sessionData.filter(topicItem => !!topicItem.subtopics?.length)?.length}
            >
              Save
            </Button>
          }
        </div>
      }
      {/* <SaveSessionModal
        isOpen={isSaveModalOpen}
        onClose={() => setIsSaveModalOpen(false)}
        saveSession={saveSession}
      /> */}
      <ConfirmationModal
        open={!!isBackLinkConfirmModalShown}
        title={t('warnings.unsavedChanges.title', {ns: 'app'})}
        description={t('warnings.unsavedChanges.description', {ns: 'app'})}
        onClose={() => navigate(`/patients/edit/${getPersonId()}`)}
        onConfirm={() => saveSession()}
        type='warning'
        confirmText={t('actions.save', {ns: 'app'})}
        confirmWarning={!sessionData.sessionData.filter(topicItem => !!topicItem.subtopics?.length)?.length
          ? 'No topics added'
          : undefined
        }
      />
    </div>
  )
}

// const SaveSessionModal:React.FC<SaveSessionModalPropType> = ({isOpen, onClose, saveSession}) => {
//   const selectedPersonData = useAppSelector(selectSelectedPersonData)

//   return (
//     <ConfirmationModal
//       open={isOpen}
//       title={`Save session for ${selectedPersonData?.name}`}
//       description='Would you like to save this session?'
//       onClose={() => onClose()}
//       onConfirm={() => saveSession()}
//       type='success'
//     />
//   )
// }

// interface SaveSessionModalPropType {
//   isOpen: boolean
//   onClose: () => void
//   saveSession: () => Promise<void>
// }

export default SessionForm
