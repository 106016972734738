import { Button, Input, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import GoBackLink from '../../../common/GoBackLink/GoBackLink'
import classes from './WGKKReport.module.css'
import { ReactComponent as CopyIcon } from './../../../../img/icons/copy.svg'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { ConfirmRequestThunk, GenerateWGKKReportAgainThunk, SaveWGKKReportThunk, selectWgkkReport, setWgkkReport } from '../../../../store/requestReducer'
import { NewRequestDataType } from '../../../../types/requestTypes'
import PopoverConfirm from '../../../common/PopoverConfirm/PopoverConfirm'
import { copyToClipboard } from '../../../../helpers/funcHelper'
import { ReactComponent as WarningNoBgIcon } from './../../../../img/icons/warningNoBg.svg'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import dayjs from 'dayjs'

const WGKKReport: React.FC<WGKKReportPropTypes> = ({goBack, requestData}) => {
  const { t } = useTranslation(['pages', 'app'])
  const dispatch = useAppDispatch()
  const wgkkReport = useAppSelector(selectWgkkReport)

  const [reportParams, setReportParams] = useState({})
  const [isSaving, setIsSaving] = useState(false)
  const [isGenerating, setIsGenerating] = useState(false)
  const [regenerateModalOpen, setRegenerateModalOpen] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)


  useEffect(() => {
    if (wgkkReport?.request_reports?.length) {
      const reportParamsData = wgkkReport?.request_reports?.find(r => r.rep_cd === 'insurance')?.rep_param
      setReportParams(reportParamsData || {})
    }
  }, [dispatch, wgkkReport])

  useEffect(() => {
    return () => {
      dispatch(setWgkkReport(null))
    }
  }, [dispatch])

  const fields = [
    {label: '1.1. Vierstellige Diagnose(n) nach ICD-10*', valueKey: 'field1_1', rows: 2},
    {label: '1.2. GAF-Wert', valueKey: 'field1_2', rows: 4},
    {label: '1.3. Berufstätigkeit', valueKey: 'field1_3', rows: 4},
    {label: '1.4. Laufende psychiatrische/kinder- und jugend (neuro-) psychiatrische (kinderfachärztliche) Behandlung', valueKey: 'field1_4', rows: 4},
    {label: '1.5. Aktuelle Medikation im Zusammenhang mit psychischen Störungen, verabreicht durch Fachärztin/Facharzt/Ärztin/Arzt für Allgemeinmedizin*:', valueKey: 'field1_5', rows: 4},
    {label: '1.6. Krankenstände im Zusammenhang mit der psychischen Erkrankung binnen der letzten zwei Jahre/vor der Erstantragstellung', valueKey: 'field1_6', rows: 4},
    {label: '1.7. Stationäre Aufenthalte im Zusammenhang mit der psychischen Erkrankung binnen der letzten zwei Jahre', valueKey: 'field1_7', rows: 4},
    {label: '1.8. Behandlungsbezogene Anamnese', valueKey: 'field1_8', rows: 4},
    {label: '1.9. Angaben zur Intensität der Störung', valueKey: 'field1_9', rows: 4},
    {label: '2.0 Konkrete Zielsetzungen', valueKey: 'field2_0', rows: 4},
    {label: '2.1 Anmerkungen', valueKey: 'field2_1', rows: 4}, 
  ]

  const symptomsFieldKeys = ['field1_1', 'field1_8', 'field1_9', 'field2_0',]

  const generateReportAgain = () => {
    setHasChanges(true)
    setIsGenerating(true)
    return dispatch(GenerateWGKKReportAgainThunk({
      requestId: wgkkReport?.request_id!,
      requestData: {
        person_id: wgkkReport?.person_id!,
        p_lang_cd: wgkkReport?.report_language!,
        report_style: wgkkReport?.report_style!,
        report_format: wgkkReport?.report_format!,
        session_list: [],
        instance_list: [],
        is_confirmed: wgkkReport?.is_confirmed,
        report_param_mode: wgkkReport?.report_param_mode!,
        date_from: requestData?.date_from,
        date_till: requestData?.date_till,
      }
    }))
      .then(() => {
        setIsGenerating(false)
        setRegenerateModalOpen(false)
      })
  }

  const saveReport = () => {
    setIsSaving(true)
    dispatch(SaveWGKKReportThunk(
      {
        requestId: wgkkReport?.request_id!,
        reportData: {
          rep_cd: 'insurance',
          req_param: reportParams
        }
      }
    ))
      .then(() => dispatch(ConfirmRequestThunk(wgkkReport?.request_id!)))
      .then(() => setIsSaving(false))
  }

  return (
    <div className={classes.wrapper}>
      <GoBackLink
        onClick={() => {
          goBack()
        }}
        text={t('generateReport.wgkk.backBtn')}
        style={{marginBottom: '10px'}}
      />
      <h1 className='pageTitle'>
        {t('generateReport.wgkk.title')}
      </h1>

      {wgkkReport === null ? (
        <div className={classes.spinnerWrapper}>
          <Spin style={{width: '100%'}} size='large' />
          <div className={classes.loadingMessageMain}>
            {t('actions.loading', {ns: 'app'})}
          </div>
          <div className={classes.loadingMessage}>
            {t('generateReport.wgkk.loadingMessage')}
          </div>
        </div>
      ) : (
        <>
          <div className={classes.dates}>
            {dayjs(wgkkReport!.date_from).format('DD.MM.YYYY')} - {dayjs(wgkkReport!.date_till).format('DD.MM.YYYY')}
          </div>
          {fields.map(f => {
            const fieldValue = reportParams?.[f.valueKey as keyof typeof reportParams]
            const isSymptomValue = symptomsFieldKeys.includes(f.valueKey)
            const hasNoSymptomInstance = !reportParams?.[symptomsFieldKeys[0] as keyof typeof reportParams]
            return (
              <Field 
                label={f.label}
                value={fieldValue}
                rows={f.rows}
                key={f.valueKey}
                onChange={(val: string) => {
                  setHasChanges(true)
                  setReportParams({...reportParams, [f.valueKey]: val})
                }}
                warning={isSymptomValue && hasNoSymptomInstance
                  ? t('generateReport.wgkk.noSymptomsWarning')
                  : (reportParams?.['not_generated_fields' as keyof typeof reportParams] as string[])?.includes(f.valueKey)
                    ? t('generateReport.wgkk.noCreditsWarning')
                    : ''
                }
              />
            )
          })}
          <div className={`actionButtons`} style={{marginBottom: '20px'}}>
            <PopoverConfirm
              title={t('warnings.cancelation', {ns: 'app'})}
              onConfirm={goBack}
              hasChanges={hasChanges}
            >
              <Button style={hasChanges? {} : {marginRight: '10px'}}>
                {t('actions.cancel', {ns: 'app'})}
              </Button>
            </PopoverConfirm>
            <Button className={classes.generateBtn} onClick={() => setRegenerateModalOpen(true)}>
              {t('actions.regenerate', {ns: 'app'})}
            </Button>
            <Button
              type='primary'
              onClick={saveReport}
              loading={isSaving}
            >
              {t('actions.save', {ns: 'app'})}
            </Button>
          </div>
          <ConfirmationModal
            open={regenerateModalOpen}
            title={t('generateReport.wgkk.generateAgainConfirmation.title')}
            description={t('generateReport.wgkk.generateAgainConfirmation.description')}
            onClose={() => setRegenerateModalOpen(false)}
            onConfirm={() => generateReportAgain()}
            type='warning'
            confirmText={t('actions.generate', {ns: 'app'})}
            isRequestLoading={!!isGenerating}
          />
        </>
      )}
    </div>
  )
}

const Field: React.FC<FieldPropTypes> = ({label, value, rows, onChange, warning}) => {
  const { t } = useTranslation(['app'])

  return (
    <div className={classes.field} id={label}>
      <div className={classes.label}>
        {label}
      </div>
      {!!warning?.length &&
        <div className={classes.warning}>
          <WarningNoBgIcon style={{width: '20px', marginRight: '5px', height: '20px'}}/>
          {warning}
        </div>
      }
      <Input.TextArea rows={rows} value={value?.replace(/(\*\*\*|\*\*|\*|###|##|#)/g, '')} onChange={(e) => onChange(e.target.value)}/>
      <Button
        onClick={() => copyToClipboard([{id: label}], 'Copied to clipboard')}
        className={classes.copyBtn}
      >
        <CopyIcon style={{marginRight: '8px'}}/> {t('actions.copy', {ns: 'app'})}
      </Button>
    </div>
  )
}

interface WGKKReportPropTypes {
  goBack: () => void
  requestData: NewRequestDataType
}

interface FieldPropTypes {
  label: string
  value: string
  rows: number
  onChange: (val: string) => void
  warning?: string
}

export default WGKKReport
