import { DatePicker, Input, Radio, Spin } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import classes from './SessionInformation.module.css'
import commonClasses from './../GenerateReport.module.css'
import { selectSelectedPersonData } from '../../../../store/personReducer'
import { GetSessionsByPersonIdThunk, clearSessionListRequestParams, selectSessionList, selectSessionListFilterCount, selectSessionListRequestParams, selectSessionListTotalCount, setSessionListRequestParams, setSessionListTotalCount } from '../../../../store/sessionReducer'
import { GetSessionInstancesByPeriodThunk } from '../../../../store/instanceReducer'
import Tag from '../../../common/Tag/Tag'
import { NewRequestDataType, ReportCDType, ReportTemplateType } from '../../../../types/requestTypes'
import { selectReportSettings } from '../../../../store/currentUserReducer'
import { ReactComponent as SessionIcon } from './../../../../img/icons/sessionWithBg.svg'
import OptionCard from '../OptionCard/OptionCard'
import { getAvailableReportTypes } from '../../../../helpers/generateReportHelper'
import { InstanceType } from '../../../../types/instanceType'

dayjs.extend(utc)

const SessionInformation:React.FC<SessionInformationPropTypes> = ({
  sessionSelectionType,
  setSessionSelectionType,
  isInstanceDataLoading,
  setIsInstanceDataLoading,
  requestData,
  setRequestData,
  isGenerating,
  getPersonId,
  settingTemplate,
  setSelectedReportTypes,
  selectedReportTypes,
  getSelectedSessionsInstances,
  setHasChanges
}) => {
  const dispatch = useAppDispatch()
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const userReportSettings = useAppSelector(selectReportSettings)
  const sessionList = useAppSelector(selectSessionList)
  const sessionListRequestParams = useAppSelector(selectSessionListRequestParams)
  const sessionListTotalCount = useAppSelector(selectSessionListTotalCount)
  const sessionListFilterCount = useAppSelector(selectSessionListFilterCount)

  const [isSessionDataLoading, setIsSessionDataLoading] = useState(false)
  const [sessionDatesRange, setSessionDatesRange] = useState<[Dayjs, Dayjs] | []>([])

  useEffect(() => {
    if (!sessionDatesRange?.length && sessionListRequestParams.person_data_request.date_from && sessionListRequestParams.person_data_request.date_to) {
      setSessionDatesRange([sessionListRequestParams.person_data_request.date_from, sessionListRequestParams.person_data_request.date_to])
    }
  }, [sessionListRequestParams, sessionDatesRange, setSessionDatesRange])

  useEffect(() => {
    if (sessionSelectionType === 'period' && sessionDatesRange?.length) {
      setIsInstanceDataLoading(true)
      dispatch(GetSessionInstancesByPeriodThunk({
        person_id: getPersonId(),
        date_from: sessionDatesRange[0].format('YYYY-MM-DDTHH:mm:ss[Z]'),
        date_till: sessionDatesRange[1].format('YYYY-MM-DDTHH:mm:ss[Z]')
      })).then(() => setIsInstanceDataLoading(false))
    } else if (sessionSelectionType === 'manual' && sessionDatesRange?.length) {
      setIsSessionDataLoading(true)
      dispatch(GetSessionsByPersonIdThunk({
        infiniteScroll: sessionListRequestParams?.pagination_request?.page !== 1,
        requestParams: {
          ...sessionListRequestParams,
          person_data_request: {
            ...sessionListRequestParams.person_data_request,
            person_id: getPersonId(),
            date_from: sessionDatesRange[0],
            date_to: sessionDatesRange[1]
          }
        }
      })).then(() => {
        setIsSessionDataLoading(false)
        dispatch(setSessionListTotalCount(0))
      })
    }
  // eslint-disable-next-line
  }, [dispatch, sessionDatesRange, sessionSelectionType, sessionListRequestParams])

  const onSessionSelectionTypeChange = (selectionType: 'period' | 'manual') => {
    setHasChanges(true)
    setSessionSelectionType(selectionType)
    const availableReportTypes = getAvailableReportTypes(getSelectedSessionsInstances())
    setSelectedReportTypes(selectedReportTypes?.filter(t => availableReportTypes.includes(t)))
    if (selectionType === 'period') {
      dispatch(clearSessionListRequestParams())
    }
  }

  const handleSessionSelection = (id: number) => {
    setHasChanges(true)
    const updatedSessionList = requestData?.session_list?.includes(id)
      ? requestData.session_list?.filter(sId => sId !== id)
      : [...(requestData?.session_list || []), id]
    if (requestData?.session_list?.includes(id)) {
      setRequestData({...requestData, session_list: updatedSessionList})
    } else {
      let instanceList: number[] | undefined = []
      if (settingTemplate === 'P' && !!selectedPersonData?.instance_list?.length) {
        instanceList = selectedPersonData?.instance_list?.some(ins => requestData?.instance_list.includes(ins.id))
          ? requestData?.instance_list.filter(i => selectedPersonData?.instance_list?.some(ins => ins.id === i))
          : requestData?.instance_list
      } else if (settingTemplate === 'U' && !!userReportSettings?.instance_list?.length) {
        instanceList = userReportSettings?.instance_list?.some(ins => requestData?.instance_list.includes(ins.id))
          ? requestData?.instance_list.filter(i => userReportSettings?.instance_list?.some(ins => ins.id === i))
          : requestData?.instance_list
      }
      setRequestData({
        ...requestData!,
        session_list: updatedSessionList,
        ...(!!instanceList?.length
          ? {instance_list: instanceList}
          : {}
        ),
      })
    }
    const availableReportTypes = getAvailableReportTypes(getSelectedSessionsInstances(updatedSessionList || undefined))
    setSelectedReportTypes(selectedReportTypes?.filter(t => availableReportTypes.includes(t)))
  }

  const getMoreSessions = () => {
    dispatch(setSessionListRequestParams({
      ...sessionListRequestParams,
      pagination_request: {
        ...sessionListRequestParams.pagination_request!,
        page: sessionListRequestParams?.pagination_request?.page! + 1
      },
    }))
  }

  return (
    <div>
      <div className={classes.titleWrapper}>
        <div>
          <h2>
            Session information 
          </h2>
          <h3>
            Select date range
          </h3>
        </div>
        <h3 style={{marginTop: '42px'}}>
          Select the sessions to request
        </h3>
      </div>
      <div className={classes.wrapper}>
        <div className={classes.field}>
          <div className={classes.label}>
            Patient
          </div>
          <Input value={selectedPersonData?.name} onChange={() => {}} style={{maxWidth: '330px'}} />
          <div className={classes.label}>
            Select Date
          </div>
          <DatePicker.RangePicker
            value={[dayjs.utc(sessionDatesRange[0]), dayjs.utc(sessionDatesRange[1])]}
            allowClear={false}
            style={{maxWidth: '330px'}}
            onChange={val => {
              setHasChanges(true)
              setRequestData({
                ...requestData!,
                date_from: dayjs.utc(val?.[0]).format('YYYY-MM-DDTHH:mm:ss[Z]'),
                date_till: dayjs.utc(val?.[1])?.endOf('day').format('YYYY-MM-DDTHH:mm:ss[Z]')
              })
              setSessionDatesRange([val?.[0], val?.[1]?.endOf('day')] as [Dayjs, Dayjs])
            }}
            disabled={!!isGenerating?.length}
          />
        </div>

        <div>
          <Radio.Group
            onChange={(e) => onSessionSelectionTypeChange(e.target.value as 'period' | 'manual')}
            value={sessionSelectionType}
            rootClassName={commonClasses.radioGroup}
            disabled={!!isGenerating?.length}
          >
            <Radio value={'period'} className={commonClasses.radioGroupOption}>
              <div className={commonClasses.name}>
                Select all
                {sessionSelectionType === 'period' && !isInstanceDataLoading && !!sessionListTotalCount &&
                  <Tag text={`${sessionListTotalCount} Selected`} style={{marginLeft: '8px'}}/>
                }
              </div>
              <div className={commonClasses.description}>
                All sessions from the period are selected by default 
              </div>
            </Radio>
            <Radio
              value={'manual'}
              className={commonClasses.radioGroupOption}
            >
              <div className={commonClasses.withSelectAll}>
                <div>
                  <div className={commonClasses.name}>
                    Manual selection
                    {!!requestData?.session_list?.length && sessionSelectionType === 'manual' &&
                      <Tag text={`${requestData?.session_list?.length} Selected`} style={{marginLeft: '8px'}}/>
                    }
                  </div>
                  <div className={commonClasses.description} style={{marginBottom: '0px'}}>
                    Possibility to mark one/several sessions from the list
                  </div>
                </div>
                <div
                  className={commonClasses.selectAll}
                  style={{marginTop: '17px', marginLeft: '10px'}}
                  onClick={() => {
                    setRequestData({
                      ...requestData!,
                      session_list: requestData?.session_list?.length === sessionList?.length
                        ? []
                        : sessionList?.map(instance => instance.id) || []
                    })
                  }}
                >
                  {sessionSelectionType === 'manual' && !!sessionList?.length && !isGenerating?.length && (
                    requestData?.session_list?.length === sessionList?.length
                      ? 'Deselect all'
                      : 'Select all'
                  )}
                </div>
              </div>
            </Radio>
          </Radio.Group>
        </div>
      </div>

      {sessionSelectionType === 'manual' && sessionList === null &&
        <Spin style={{width: '100%'}}/>
      }
      {sessionSelectionType === 'manual' && sessionList !== null &&
        <div className={classes.sessionsWrapper}>
          {sessionList?.length
            ? (sessionList.map(session => (
              <OptionCard
                key={session.id}
                onClick={() => handleSessionSelection(session.id)}
                isSelected={!!requestData?.session_list?.includes(session.id)}
                disabled={!!isGenerating?.length}
              >
                <SessionIcon /> Session {dayjs(session.rec_date).format('DD.MM.YYYY')}
              </OptionCard>
            ))) : (
              <div className={classes.noOptions}>
                No sessions found
              </div>
            )
          }
        </div>
      }
      {sessionSelectionType === 'manual' && sessionListFilterCount > (sessionList?.length || 0) && (
        isSessionDataLoading
          ? <Spin className={classes.seeMore} style={{width: '100%'}} />
          : (
            <div className={classes.seeMoreWrapper}>
              <div
                onClick={() => !!isGenerating?.length ? {} : getMoreSessions()}
                className={classes.seeMore}
                style={{cursor: !!isGenerating?.length ? 'default' : 'pointer'}}
              >
                See more
              </div>
              <div className={classes.seeMore}>
                Sessions {sessionList?.length} of {sessionListFilterCount}
              </div>
            </div>
          )
      )}
    </div>
  )
}

interface SessionInformationPropTypes {
  sessionSelectionType: 'period' | 'manual'
  setSessionSelectionType: (val: 'period' | 'manual') => void
  isInstanceDataLoading: boolean
  setIsInstanceDataLoading: (val: boolean) => void
  requestData: NewRequestDataType | null
  setRequestData: (val: NewRequestDataType | null) => void
  isGenerating: ReportCDType[]
  getPersonId: () => number
  settingTemplate: ReportTemplateType
  setSelectedReportTypes: (types: ReportCDType[]) => void
  selectedReportTypes: ReportCDType[]
  getSelectedSessionsInstances: (sessionList?: number[]) => InstanceType[]
  setHasChanges: (val: boolean) => void
}

export default SessionInformation
