import { Divider, Spin } from 'antd'
import Tag from '../../../../common/Tag/Tag'
import OptionCard from '../../OptionCard/OptionCard'
import classes from './Instances.module.css'
import { NewRequestDataType, ReportCDType, ReportTemplateType } from '../../../../../types/requestTypes'
import { InstanceType } from '../../../../../types/instanceType'
import { ReactComponent as WarningNoBg } from './../../../../../img/icons/warningNoBg.svg'
import { checkInstancesForConflicts } from '../../../../../helpers/generateReportHelper'
import InstanceSettingOptions from './InstanceSettingOptions/InstanceSettingOptions'
import { selectSelectedPersonData } from '../../../../../store/personReducer'
import { useAppSelector } from '../../../../../app/hooks'

const Instances: React.FC<InstancesPropTypes> = ({
  isGenerating,
  settingTemplate,
  requestData,
  setRequestData,
  validInstanceList,
  getSelectedSessionsInstances,
  sessionSelectionType,
  isInstanceDataLoading,
  setSettingTemplate,
  activeReportType,
  setHasChanges,
}) => {
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const conflictsReportInstancesData = checkInstancesForConflicts(getSelectedSessionsInstances())

  const checkIfInstanceDisabled = () => {
    return !!isGenerating.length
      || settingTemplate !== 'R'
      || activeReportType !== 'main'
  }

  const handleInstanceSelection = (id: number) => {
    setHasChanges(true)
    if (requestData?.instance_list?.includes(id)) {
      setRequestData({...requestData, instance_list: requestData?.instance_list?.filter(iId => iId !== id)})
    } else {
      setRequestData({...requestData!, instance_list: [...requestData?.instance_list || [], id]})
    }
  }
  
  const getInstancesBySettingsType = () => {
    const allAvailableInstances = getSelectedSessionsInstances()
    return {
      person: allAvailableInstances.filter(inst => selectedPersonData?.instance_list?.some(pInst => pInst.id === inst.id)),
      fromSessions: allAvailableInstances.filter(inst => !selectedPersonData?.instance_list?.some(pInst => pInst.id === inst.id)),
    }
  }

  const getSelectedInstanceCount = () => {
    if (activeReportType === 'main' || activeReportType === 'literature' || activeReportType === 'keywords') {
      return {
        person: getInstancesBySettingsType()?.person?.filter(inst => validInstanceList?.some(vInst => vInst.id === inst.id))?.length,
        fromSessions: getInstancesBySettingsType()?.fromSessions?.filter(inst => validInstanceList?.some(vInst => vInst.id === inst.id))?.length
      }
    } else if (activeReportType === 'diagnoses' || activeReportType === 'kernberg') {
      return {
        person: getInstancesBySettingsType()?.person?.filter(inst => inst.name.toLowerCase() === 'symptoms')?.length,
        fromSessions: getInstancesBySettingsType()?.fromSessions?.filter(inst => inst.name.toLowerCase() === 'symptoms')?.length
      }
    } else {
      return {
        person: getInstancesBySettingsType()?.person?.filter(inst => conflictsReportInstancesData?.availableRequiredInstances?.some(i => i.id === inst.id))?.length,
        fromSessions: getInstancesBySettingsType()?.fromSessions?.filter(inst => conflictsReportInstancesData?.availableRequiredInstances?.some(i => i.id === inst.id))?.length
      }
    }
  }

  const isOptionSelected = (instance: InstanceType) => {
    if (activeReportType === 'main' || activeReportType === 'literature' || activeReportType === 'keywords') {
      return !!requestData?.instance_list?.includes(instance.id)
    } else if (activeReportType === 'diagnoses' || activeReportType === 'kernberg') {
      return instance.name.toLowerCase() === 'symptoms'
    } else {
      return conflictsReportInstancesData?.availableRequiredInstances?.some(i => i.id === instance.id)
    }
  }
  
  return (
    <div>
      <h2 style={{marginTop: '0px'}}>
        Settings information
      </h2>

      {(activeReportType === 'diagnoses' || activeReportType === 'kernberg' || activeReportType === 'conflicts') &&
        <>
          <Tag
            text={activeReportType === 'conflicts'
              ? '“Id”, “Ego”, “Super-Ego” and “External Reality” are used to conflicts.'
              : 'Symptoms are used to diagnosis'
            }
            style={{
              backgroundColor: '#E0EFFE',
              color: '#007DFA',
              padding: '4px 10px',
              width: 'fit-content',
              marginBottom: '13px'
            }}
          />
          <div className={classes.instancesInformation}>
            {[...getInstancesBySettingsType().fromSessions, ...getInstancesBySettingsType().person]
              .filter(instance => isOptionSelected(instance))
              .map(instance => (
                <OptionCard
                  key={instance.id}
                  onClick={() => handleInstanceSelection(instance?.id)}
                  isSelected={isOptionSelected(instance)}
                  disabled={checkIfInstanceDisabled()}
                >
                  {instance.name}
                </OptionCard>
              ))
            }
          </div>

          {(activeReportType === 'diagnoses' || activeReportType === 'kernberg') && !getSelectedSessionsInstances().some(i => i.name.toLowerCase() === 'symptoms') &&
            <div className={classes.noOptions}>
              <WarningNoBg /> No data in the “Symptoms” instance, select another report type or fill in patient data to generate this report.
            </div>
          }

          {activeReportType === 'conflicts' && !conflictsReportInstancesData?.isReportAvailable &&
            <div className={classes.noOptions}>
              <WarningNoBg /> {`No data in the required instances ${'('+conflictsReportInstancesData.notAvailableRequiredInstances.join(', ')+')'}, select another report type or fill in patient data to generate this report.`}
            </div>
          }
        </>
      }

      {(activeReportType === 'main' || activeReportType === 'literature' || activeReportType === 'keywords') &&
        <>
          <h3>
            Select template option
          </h3>
          
          <InstanceSettingOptions
            requestData={requestData}
            setRequestData={setRequestData}
            settingTemplate={settingTemplate}
            setSettingTemplate={setSettingTemplate}
            isGenerating={isGenerating}
            activeReportType={activeReportType}
          />

          {!isInstanceDataLoading && !getInstancesBySettingsType()?.person?.length && !getInstancesBySettingsType()?.fromSessions?.length &&
            <div className={classes.noOptions} style={{marginBottom: '5px'}}>
              {sessionSelectionType === 'period' ? 'No sessions for selected period found' : 'Select at least one session'}
            </div>
          }

          {!isInstanceDataLoading && (!!getInstancesBySettingsType()?.person?.length || !!getInstancesBySettingsType()?.fromSessions?.length) &&
            <>
              <h3 style={{display: 'flex', justifyContent: 'space-between'}}>
                <div style={{display: 'flex'}}>
                  Instances that are included in the patient setting
                  {!!getSelectedInstanceCount()?.person &&
                    <Tag text={`${getSelectedInstanceCount().person} Selected`} style={{marginLeft: '8px'}}/>            
                  }
                </div>
              </h3>
              <div className={classes.instancesInformation}>
                {!!getInstancesBySettingsType()?.person?.length ? (
                  <>
                    {getInstancesBySettingsType()?.person?.map(instance => (
                      <OptionCard
                        key={instance.id}
                        onClick={() => handleInstanceSelection(instance?.id)}
                        isSelected={isOptionSelected(instance)}
                        disabled={checkIfInstanceDisabled()}
                      >
                        {instance.name}
                      </OptionCard>
                    ))}
                  </>
                ) : (
                  <div className={classes.noOptions}>
                    <WarningNoBg /> Selected sessions have no instances from patient settings
                  </div>
                )} 
              </div>
              {!!getInstancesBySettingsType()?.fromSessions?.length &&
                <>
                  <Divider />
                  <h3>
                    <div style={{display: 'flex'}}>
                      {settingTemplate === 'R'
                        ? 'Additional instances that were filled in during the sessions'
                        : 'There are filled instances that are not in the patient settings, to select them, change the template option to "Select other settings".'
                      }
                      {!!getSelectedInstanceCount()?.fromSessions &&
                        <Tag
                          text={`${getSelectedInstanceCount()?.fromSessions} Selected`}
                          style={{
                            marginLeft: '8px',
                            minWidth: '80px',
                            height: 'fit-content'
                          }}
                        />            
                      }
                    </div>
                  </h3>
                  <div className={classes.instancesInformation}>
                    {getInstancesBySettingsType()?.fromSessions?.map(instance => (
                      <OptionCard
                        key={instance.id}
                        onClick={() => handleInstanceSelection(instance?.id)}
                        isSelected={isOptionSelected(instance)}
                        disabled={checkIfInstanceDisabled()}
                      >
                        {instance.name}
                      </OptionCard>
                    ))}
                  </div>
                </>
              }
            </>
          }
        </>
      }

      {isInstanceDataLoading &&
        <Spin style={{marginTop: '10px'}}/> 
      }
    </div>
  )
}

interface InstancesPropTypes {
  isGenerating: ReportCDType[]
  settingTemplate: ReportTemplateType
  setRequestData: (requestData: NewRequestDataType | null) => void
  requestData: NewRequestDataType | null
  validInstanceList: InstanceType[] | undefined
  getSelectedSessionsInstances: () => InstanceType[]
  sessionSelectionType: 'period' | 'manual'
  isInstanceDataLoading: boolean
  setSettingTemplate: (template: ReportTemplateType) => void
  activeReportType: ReportCDType
  setHasChanges: (val: boolean) => void
}

export default Instances
