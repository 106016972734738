import { Button, Divider, Form, Input, Select } from 'antd'
import commonClasses from './../Parameters.module.css'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { UpdateUserMainDataThunk, selectSuperUserCurrentUser, selectUserData } from '../../../store/currentUserReducer'
import { getUIPermission } from '../../../store/appStatusReducer'
import PopoverConfirm from '../../common/PopoverConfirm/PopoverConfirm'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import TooltipHint from '../../common/TooltipHint/TooltipHint'
import { ReactComponent as HelpIcon } from './../../../img/icons/help.svg'
import { selectTherapyMethods } from '../../../store/referenceReducer'

interface ProfileProps {
  hasChanges: boolean
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>
}

const Profile: React.FC<ProfileProps> = ({hasChanges, setHasChanges}) => {
  const { t } = useTranslation(['forms'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const userData = useAppSelector(selectUserData)
  const allowEditing = useAppSelector(getUIPermission('EditForm.Preferences'))
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)
  const therapyMethods = useAppSelector(selectTherapyMethods)

  const password_old = Form.useWatch('password_old', form)
  const password_new = Form.useWatch('password_new', form)

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      first_name: superUserCurrentUser?.first_name || userData?.first_name,
      last_name: superUserCurrentUser?.last_name || userData?.last_name,
      therapy_method_id: superUserCurrentUser?.user_id
        ? superUserCurrentUser?.therapy_method_id
        : userData?.therapy_method_id,
    })
  }, [userData, form, superUserCurrentUser])

  const hasToMatchValidator = (valueToCompare: string, errorText: string) => ({
    validator(_: any, value: any) {
      if (!value || valueToCompare === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error(errorText))
    }
  })

  const handleSave = (formValues: any) => {
    setIsSaving(true)
    dispatch(UpdateUserMainDataThunk({userData: formValues, userId: superUserCurrentUser?.user_id || userData?.user_id}))
      .then(() => setIsSaving(false))
  }

  return (
    <>
      <Form
        name='profileSettings'
        onFinish={handleSave}
        autoComplete='off'
        validateTrigger='onBlur'
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            First Name
          </div>
          <div className={commonClasses.inputWrapper}>
            <Form.Item
              name='first_name'
              rules={[{required: true, message:'This field is required'}]}
              style={{width: '100%'}}
            >
              <Input placeholder='First Name'/>
            </Form.Item>
          </div>
        </div>

        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            Last Name
          </div>
          <div className={commonClasses.inputWrapper}>
            <Form.Item
              name='last_name'
              rules={[{required: true, message:'This field is required'}]}
              style={{width: '100%'}}
            >
              <Input placeholder='Last Name'/>
            </Form.Item>
          </div>
        </div>

        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            {t('fields.therapyMethods.label')}
          </div>
          <div className={commonClasses.inputWrapper}>
            <Form.Item name='therapy_method_id' style={{width: '100%'}}>
              <Select
                placeholder={t('fields.therapyMethods.placeholder')}
                options={therapyMethods?.map(ref => ({label: ref.name, value: ref.id}))}
                getPopupContainer={() => document.getElementById('person-output-method-select')!}
                allowClear
              />
            </Form.Item>
            <TooltipHint
              overlayInnerStyle={{width: '400px'}}
              placement='right'
              title={
                <>
                  <ul style={{padding: '10px', margin: '0px'}}>
                    {t(`fields.therapyMethods.patientHintTitle`)}
                    {therapyMethods?.map(method => (
                      <li key={method.id} style={{fontWeight: 400, paddingLeft: '5px', marginLeft: '15px'}}>
                        <span style={{fontWeight: 600, marginRight: '6px', color: '#344054'}}>
                          {method.name}:
                        </span>
                        <span style={{color: '#667085'}}>
                          {method.description}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              }
            >
              <HelpIcon />
            </TooltipHint>
          </div>
        </div>

        {/* <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.therapyMethods.label')}
          </div>
          <div className={classes.inputWrapper} id='person-output-method-select'>
            <Form.Item name='therapy_method_id' style={{width: '100%'}}>
              <Select
                placeholder={t('fields.therapyMethods.placeholder')}
                options={therapyMethods?.map(ref => ({label: ref.name, value: ref.id}))}
                getPopupContainer={() => document.getElementById('person-output-method-select')!}
                allowClear
              />
            </Form.Item>
            <TooltipHint
              overlayInnerStyle={{width: '400px'}}
              placement='right'
              title={
                <>
                  <ul style={{padding: '10px', margin: '0px'}}>
                    {t(`fields.therapyMethods.patientHintTitle`)}
                    {therapyMethods?.map(method => (
                      <li key={method.id} style={{fontWeight: 400, paddingLeft: '5px', marginLeft: '15px'}}>
                        <span style={{fontWeight: 600, marginRight: '6px', color: '#344054'}}>
                          {method.name}:
                        </span>
                        <span style={{color: '#667085'}}>
                          {method.description}
                        </span>
                      </li>
                    ))}
                  </ul>
                </>
              }
            >
              <HelpIcon />
            </TooltipHint>
          </div>
        </div> */}

        <Divider />

        <div className={commonClasses.blockTitle}>
          Password
        </div>
        <div className={commonClasses.blockDescription}>
          Please enter your current password to change your password.
        </div>

        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            Current password
          </div>
          <div className={commonClasses.inputWrapper}>
            <Form.Item
              name='password_old'
              rules={password_new?.length ? [{required: true, message:'This field is required'}] : []}
              style={{width: '100%'}}
            >
              <Input.Password placeholder='Current password'/>
            </Form.Item>
          </div>
        </div>

        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            New password
          </div>
          <div className={commonClasses.inputWrapper}>
            <Form.Item
              name='password_new'
              rules={password_old?.length ? [{required: true, message:'This field is required'}] : []}
              style={{width: '100%'}}
            >
              <Input.Password placeholder='New password'/>
            </Form.Item>
          </div>
        </div>

        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            Confirm new password
          </div>
          <div className={commonClasses.inputWrapper}>
            <Form.Item
              name='password_repeat'
              dependencies={['password_new']}
              rules={[
                ...(password_new?.length ? [{required: true, message: 'Please repeat your password!'}] : []),
                ({getFieldValue}) => hasToMatchValidator(getFieldValue('password_new'), 'The two passwords that you entered do not match!'),
              ]}
              style={{width: '100%'}}
            >
              <Input.Password placeholder='Confirm new password'/>
            </Form.Item>
          </div>
        </div>

        {((allowEditing && !superUserCurrentUser?.user_id) || superUserCurrentUser?.is_allow_edit_param) &&
          <div className={`${commonClasses.actionButtons} actionButtons`}>  
            {
              <PopoverConfirm
                title={t('warnings.cancelation', {ns: 'app'})}
                onConfirm={() => navigate('/patients')}
                hasChanges={hasChanges}
              >
                <Button>
                  Cancel
                </Button>
              </PopoverConfirm>
            }
            {hasChanges &&
              <Form.Item>   
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isSaving}
                >
                  Save
                </Button>
              </Form.Item>
            }
          </div>
        }
      </Form>
    </>
  )
}

export default Profile
