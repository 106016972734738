import { Popconfirm } from 'antd'
import { useTranslation } from 'react-i18next'

const PopoverConfirm: React.FC<PopoverConfirmPropTypes> = ({title, onConfirm, children, hasChanges}) => {
  const { t } = useTranslation(['app'])

  if (hasChanges === false) {
    return (
      <div onClick={onConfirm}>
        {children}
      </div>
    )
  }

  return (
    <Popconfirm
      title={title}
      onConfirm={onConfirm}
      okText={t('actions.yes')}
      cancelText={t('actions.no')}
      okButtonProps={{style: {lineHeight: '20px'}}}
      cancelButtonProps={{style: {lineHeight: '20px'}}}
    >
      {children}
    </Popconfirm>
  )
}

interface PopoverConfirmPropTypes {
  title: string
  onConfirm: () => void
  children: any
  hasChanges?: boolean
}

export default PopoverConfirm
