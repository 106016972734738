import { useNavigate } from 'react-router-dom'
import { Button, Form, Select } from 'antd'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { UpdateUserParametersThunk, selectReportSettings, selectSuperUserCurrentUser, selectUserData } from '../../../store/currentUserReducer'
import classes from './Preferences.module.css'
import commonClasses from './../Parameters.module.css'
import TagMultiSelect from '../../common/TagMultiSelect/TagMultiSelect'
import PopoverConfirm from '../../common/PopoverConfirm/PopoverConfirm'
import { selectFormatReferences, selectLanguageReferences, selectStyleReferences } from '../../../store/referenceReducer'
import { selectInstanceList } from '../../../store/instanceReducer'
import { ReactComponent as FileIcon } from './../../../img/icons/file.svg'
import { ReactComponent as HelpIcon } from './../../../img/icons/help.svg'
import reportStylesExample from './../../../files/reportStylesExamples.pdf'
import reportFormatsExample from './../../../files/reportFormatExamples.pdf'
import { getUIPermission } from '../../../store/appStatusReducer'
import TooltipHint from '../../common/TooltipHint/TooltipHint'
import { sortBy } from 'lodash'
import { useTranslation } from 'react-i18next'

interface PreferencesProps {
  hasChanges: boolean
  setHasChanges: React.Dispatch<React.SetStateAction<boolean>>
}

const Preferences: React.FC<PreferencesProps> = ({hasChanges, setHasChanges}) => {
  const { t } = useTranslation(['app'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const styleReferences = useAppSelector(selectStyleReferences)
  const languageReferences = useAppSelector(selectLanguageReferences)
  const formatReferences = useAppSelector(selectFormatReferences)
  const instanceList = useAppSelector(selectInstanceList)
  const userData = useAppSelector(selectUserData)
  const userReportSettings = useAppSelector(selectReportSettings)
  const allowEditing = useAppSelector(getUIPermission('EditForm.Preferences'))
  const superUserCurrentUser = useAppSelector(selectSuperUserCurrentUser)

  const [isSaving, setIsSaving] = useState(false)

  useEffect(() => {
    form.setFieldsValue({
      instance_list: sortBy(userReportSettings?.instance_list, i => i.order_num)?.map(instance => String(instance.id)) || [],
      lang_cd: userReportSettings?.lang_cd,
      report_format: userReportSettings?.report_format,
      report_style: userReportSettings?.report_style
    })
  }, [userReportSettings, form])

  const handleSave = (reportSettings: {
    instance_list: string[],
    lang_cd: string,
    report_format: string,
    report_style: string
  }) => {
    const updatedUserData = {
      instance_list: instanceList?.filter(i => reportSettings.instance_list.some(instId => instId === String(i.id))) || [],
      report_style: reportSettings.report_style,
      report_format: reportSettings.report_format,
      lang_cd: reportSettings.lang_cd
    }
    setIsSaving(true)
    dispatch(UpdateUserParametersThunk({
      userId: userData.user_id,
      userData: updatedUserData 
    }))
      .then(() => setIsSaving(false))
  }

  return (
    <>
      <Form
        name='reportSettings'
        onFinish={handleSave}
        autoComplete='off'
        validateTrigger='onBlur'
        form={form}
        onValuesChange={() => setHasChanges(true)}
      >
        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            Instances
          </div>
          <div className={commonClasses.inputWrapper} id='user-instances-select'>
            <Form.Item
              name='instance_list'
              rules={[{required: true, message:'Please select instances'}]}
              style={{width: '100%'}}
            >
              <TagMultiSelect
                placeholder='Select instances'
                options={sortBy(instanceList, i => i.order_num)?.map(instance => ({label: instance.name, value: String(instance.id), color: instance.color})) || []}
                getPopupContainer={() => document.getElementById('user-instances-select')!}   
                tagColor='#3B414B'     
              />
            </Form.Item>
            <TooltipHint title='These instances will be used by default when the patient is being created'>
              <HelpIcon />
            </TooltipHint>
          </div>
        </div>

        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            Output Language
          </div>
          <div className={commonClasses.inputWrapper} id='person-output-language-select'>
            <Form.Item
              name='lang_cd'
              rules={[{required: true, message:'Please select language'}]}
              style={{width: '100%'}}
            >
              <Select
                placeholder='Select language'
                options={languageReferences?.map(ref => ({label: ref.language, value: ref.lang_cd}))}
                getPopupContainer={() => document.getElementById('person-output-language-select')!}
              />
            </Form.Item>
          </div>
        </div>

        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            Report Styles
          </div>
          <div className={commonClasses.inputWrapper} id='person-output-style-select'>
            <Form.Item
              name='report_style'
              rules={[{required: true, message:'Please select styles'}]}
              style={{width: '100%'}}
            >
              <Select
                placeholder='Select styles'
                options={styleReferences?.map(ref => ({label: ref.style, value: ref.style}))}
                getPopupContainer={() => document.getElementById('person-output-style-select')!}
              />
            </Form.Item>
            <a
              href={reportStylesExample}
              className={classes.viewExamples}
              target='_blank'
              rel='noopener noreferrer'
            >
              <FileIcon />View Examples
            </a>
          </div>
        </div>

        <div className={commonClasses.formBlock}>
          <div className={commonClasses.label}>
            Report Format
          </div>
          <div className={commonClasses.inputWrapper} id='person-output-format-select'>
            <Form.Item
              name='report_format'
              rules={[{required: true, message:'Please select format'}]}
              style={{width: '100%'}}
            >
              <Select
                placeholder='Select format'
                options={formatReferences?.map(ref => ({label: ref.format, value: ref.format}))}
                getPopupContainer={() => document.getElementById('person-output-format-select')!}
              />
            </Form.Item>
            <a
              href={reportFormatsExample}
              className={classes.viewExamples}
              target='_blank'
              rel='noopener noreferrer'
            >
              <FileIcon />View Examples
            </a>
          </div>
        </div>
        {((allowEditing && !superUserCurrentUser?.user_id) || superUserCurrentUser?.is_allow_edit_param) &&
          <div className={`${commonClasses.actionButtons} actionButtons`}>  
            {
              <PopoverConfirm
                title={t('warnings.cancelation')}
                onConfirm={() => navigate('/patients')}
                hasChanges={hasChanges}
              >
                <Button>
                  Cancel
                </Button>
              </PopoverConfirm>
            }
            {hasChanges &&
              <Form.Item>   
                <Button
                  type='primary'
                  htmlType='submit'
                  loading={isSaving}
                >
                  Save
                </Button>
              </Form.Item>
            }
          </div>
        }
      </Form>
    </>
  )
}

export default Preferences
