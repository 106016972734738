import { Button, ColorPicker, Divider, Spin } from 'antd'
import { useEffect, useState } from 'react'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classes from './InstanceForm.module.css'
import GoBackLink from '../../common/GoBackLink/GoBackLink'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import {
  CreateInstanceThunk,
  EditInstanceThunk,
  GetInstanceByIdThunk,
  GetInstanceListThunk, selectBackLinkModalOpened,
  selectCurrentInstance,
  selectInstanceListRequestParams,
  selectInstanceListTotalCount,
  setBackLinkModalOpened,
  setCurrentInstance
} from '../../../store/instanceReducer'
import Tag from '../../common/Tag/Tag'
import TopicCard from './TopicCard/TopicCard'
import {
  InstanceFormType,
  InstanceSubtopicType,
  InstanceTopicType,
  NewInstanceSubtopicType,
  NewInstanceTopicType,
  NewInstanceType
} from '../../../types/instanceType'
import PopoverConfirm from '../../common/PopoverConfirm/PopoverConfirm'
import TopicForm from './TopicForm/TopicForm'
import { selectIsAdmin, selectIsSuperUser } from '../../../store/currentUserReducer'
import TitleDescriptionFields from './TitleDescriptionFields'
import { generateTempId } from '../../../helpers/funcHelper'
import ConfirmationModal from '../../common/ConfirmationModal/ConfirmationModal'
import { sortBy } from 'lodash'

const defaultColor = '#d2f6f7'

const InstanceForm: React.FC<{ isEditing: boolean }> = ({ isEditing }) => {
  const { t } = useTranslation(['app'])
  const dispatch = useAppDispatch()
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const isAdmin = useAppSelector(selectIsAdmin)
  const isSuperUser = useAppSelector(selectIsSuperUser)
  const currentInstance = useAppSelector(selectCurrentInstance)
  const instanceListTotalCount = useAppSelector(selectInstanceListTotalCount)
  const instanceListRequestParams = useAppSelector(selectInstanceListRequestParams)

  const [formValues, setFormValues] = useState<InstanceFormType>({
    instance: {
      order_num: 0,
      name: '',
      description: '',
      color: defaultColor
    },
    addedTopics: [],
    editedTopics: [],
    deletedTopics: [],
    addedSubtopics: [],
    editedSubtopics: [],
    deletedSubtopics: [],
  })
  const [activeForm, setActiveForm] = useState<'instance' | 'topic'>('instance')
  const [selectedTopic, setSelectedTopic] = useState<null | SelectedTopicType>(null)
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [hasInstanceChanged, setHasInstanceChanged] = useState(false)
  const [hasTopicChanged, setHasTopicChanged] = useState(false)

  useEffect(() => {
    //pathname example: /instances/edit/6
    const id = pathname.split('/instances/edit/')[1]
    if ((isEditing && currentInstance?.id !== +id) || !instanceListTotalCount) {
      setIsDataLoading(true)
      Promise.all([
        ...(instanceListTotalCount ? [] : [dispatch(GetInstanceListThunk(instanceListRequestParams))]),
        ...((isEditing && currentInstance?.id !== +id) ? [dispatch(GetInstanceByIdThunk(+id))] : [])
      ])
        .then(() => setIsDataLoading(false))
    }
  }, [dispatch, currentInstance, isEditing, pathname, instanceListTotalCount, instanceListRequestParams])

  useEffect(() => {
    return () => {
      dispatch(setCurrentInstance(null))
    }
  }, [dispatch])

  const getSubtopicsForTopic = (topic: NewInstanceTopicType) => {
    return topic?.id !== 0
      ? (
        [
          ...formValues.addedSubtopics,
          ...(currentInstance?.subtopics || [])
            ?.filter(s => !formValues.deletedSubtopics.includes(s.id))
            ?.map(s => formValues.editedSubtopics.some(sub => sub.id === s.id) ? { ...s, ...formValues.editedSubtopics.find(sub => sub.id === s.id) }! : s)
        ].filter(s => (s as InstanceSubtopicType).topic_id! === topic?.id)
      ) : (
        formValues.addedSubtopics.filter(s => !s?.topic_id && s?.temp_topic_id === topic?.temp_id)
      )
  }

  const goToTopicForm = (topic?: NewInstanceTopicType) => {
    if (!!topic) {
      setSelectedTopic({
        name: topic.name,
        description: topic.description,
        id: topic?.id,
        temp_id: topic?.temp_id,
        isEditing: true,
        subtopic_count: topic?.id !== 0
          ? topic?.subtopic_count
          : formValues.addedSubtopics.filter(s => s?.temp_topic_id === topic?.temp_id)?.length,
        subtopics: getSubtopicsForTopic(topic),
        order_num: topic?.order_num
      })
    } else {
      setSelectedTopic({
        name: '',
        description: '',
        id: 0,
        temp_id: generateTempId(formValues.addedTopics),
        isEditing: false,
        subtopic_count: 0,
        subtopics: []
      })
    }
    setActiveForm('topic')
  }

  const goToInstanceForm = () => {
    setSelectedTopic(null)
    setActiveForm('instance')
  }

  const getValidationWarning = () => {
    if (activeForm === 'instance' && !formValues.instance.name) {
      return 'Instance name is required'
    } else if (activeForm === 'instance' && !formValues.instance.description) {
      return 'Description is required'
    } else if (activeForm === 'topic' && !selectedTopic?.name?.length) {
      return 'Topic name is required'
    } else if (activeForm === 'topic' && !selectedTopic?.description?.length) {
      return 'Description is required'
    } else if (activeForm === 'topic' && selectedTopic?.subtopics?.some(s => !s.name.length)) {
      return 'All the subtopics must have a title'
    } else if (activeForm === 'topic' && selectedTopic?.subtopics?.some(s => !s.description.length)) {
      return 'All the subtopics must have a description'
    } else {
      return ''
    }
  }

  const saveRequest = async (data: InstanceFormType) => {
    if (isEditing) {
      return dispatch(EditInstanceThunk({
        instanceId: +pathname.split('/instances/edit/')[1],
        formValues: data
      }))
    } else {
      return dispatch(CreateInstanceThunk(data))
    }
  }

  const handleTopicChanges = () => {
    const isAlreadyEdited = !!selectedTopic?.id && !!formValues?.editedTopics?.some(t => t.id === selectedTopic?.id)
    let editedTopics = formValues.editedTopics
    let addedTopics = formValues.addedTopics
    let deletedSubtopics = formValues.deletedSubtopics
    let editedSubtopics = formValues.editedSubtopics
    let addedSubtopics = formValues.addedSubtopics
    const updatedTopicData = {
      name: selectedTopic?.name!,
      description: selectedTopic?.description!,
      subtopic_count: selectedTopic?.subtopic_count!,
      id: selectedTopic?.id || 0,
      ...(!!selectedTopic?.id ? {
        order_num: selectedTopic?.order_num,
      } : {
        temp_id: selectedTopic?.temp_id,
        order_num: isEditing
          ? currentInstance?.topic_count! - formValues.deletedTopics.length + formValues.addedTopics.length + 1
          : formValues.addedTopics.length + 1,
      })
    }
    if (!!selectedTopic?.id) {
      editedTopics = isAlreadyEdited
        ? formValues.editedTopics.map(t => t.id === selectedTopic.id ? updatedTopicData : t)
        : [...formValues.editedTopics, updatedTopicData]
    } else if (!!selectedTopic?.temp_id) {
      addedTopics = !!selectedTopic?.isEditing
        ? formValues.addedTopics.map(t => t?.temp_id === selectedTopic?.temp_id ? updatedTopicData : t)
        : [...formValues.addedTopics, updatedTopicData]
    }
    if (selectedTopic?.subtopicsAdded) {
      const newlyAdded = selectedTopic?.subtopicsAdded
        .filter(s => !addedSubtopics.some(sub => sub.temp_id === s.temp_id))
        .map(s => ({
          ...(selectedTopic?.id ? { topic_id: selectedTopic.id } : { temp_topic_id: selectedTopic.temp_id }),
          order_num: s.order_num,
          name: s.name,
          description: s.description,
          temp_id: s.temp_id,
          short_description: ''
        }))
      addedSubtopics = [
        ...addedSubtopics.map(s => {
          const addedSubtopic = selectedTopic?.subtopicsAdded?.find(sub => sub.temp_id === s.temp_id)
          return addedSubtopic?.temp_id
            ? {
              ...(selectedTopic?.id ? { topic_id: selectedTopic.id } : { temp_topic_id: selectedTopic.temp_id }),
              order_num: addedSubtopic.order_num,
              name: addedSubtopic.name,
              description: addedSubtopic.description,
              temp_id: addedSubtopic.temp_id,
              short_description: ''
            }
            : s
        }),
        ...newlyAdded
      ]
    }
    if (selectedTopic?.subtopicsDeletedWithTempId) {
      addedTopics = addedTopics.filter(t => !selectedTopic?.subtopicsDeletedWithTempId?.includes(t?.temp_id!))
    }
    if (selectedTopic?.subtopicsEdited) {
      const newlyEdited = selectedTopic?.subtopicsEdited.filter(s => !editedSubtopics.some(sub => sub.id === s.id))
      editedSubtopics = [
        ...editedSubtopics.map(s => {
          const editedData = selectedTopic?.subtopicsEdited?.find(sub => sub.id === s.id)
          return s.id === editedData?.id ? { ...s, ...editedData } : s
        }),
        ...newlyEdited
      ]
    }
    if (selectedTopic?.subtopicsDeleted?.length) {
      deletedSubtopics = [...deletedSubtopics, ...selectedTopic?.subtopicsDeleted]
      if (editedSubtopics?.some(s => selectedTopic?.subtopicsDeleted?.includes(s.id!))) {
        editedSubtopics = editedSubtopics.filter(s => !selectedTopic?.subtopicsDeleted?.includes(s.id!))
      }
    }
    setFormValues({
      ...formValues,
      editedTopics,
      addedTopics,
      deletedSubtopics,
      editedSubtopics,
      addedSubtopics
    })
    goToInstanceForm()
    return Promise.resolve()
  }

  const onSubmit = () => {
    dispatch(setBackLinkModalOpened(false))
    if (activeForm === 'topic') {
      handleTopicChanges()
    } else {
      const data = {
        ...formValues,
        instance: {
          ...formValues.instance,
          order_num: isEditing ? currentInstance?.order_num : instanceListTotalCount + 1,
        } as NewInstanceType
      }
      setIsSaving(true)
      return saveRequest(data).then((resp) => {
        setIsSaving(false)
        !resp.type.includes('rejected') && navigate('/instances')
      })
    }
  }

  if (!isAdmin && !isSuperUser) {
    return <Navigate to='/' />
  }

  if (isDataLoading) {
    return <Spin style={{ width: '100%' }} />
  }

  return (
    <div className={classes.wrapper}>
      {activeForm === 'instance' &&
        <InstanceFormBlock
          formValues={formValues}
          setFormValues={(values: InstanceFormType) => setFormValues(values)}
          isEditing={isEditing}
          goToTopicForm={goToTopicForm}
          onSubmit={onSubmit}
          hasInstanceChanged={hasInstanceChanged}
          setHasInstanceChanged={setHasInstanceChanged}
          hasTopicChanged={hasTopicChanged}
          setHasTopicChanged={setHasTopicChanged}
        />
      }
      {activeForm === 'topic' &&
        <TopicForm
          topic={selectedTopic!}
          setTopicData={(data: SelectedTopicType) => setSelectedTopic(data)}
          goToInstanceForm={goToInstanceForm}
          instanceName={formValues?.instance?.name}
          allowEdit={currentInstance?.is_allow_edit || !isEditing}
          // @ts-ignore
          onSubmit={onSubmit}
          hasTopicChanged={hasTopicChanged}
          setHasTopicChanged={setHasTopicChanged}
          validationWarning={getValidationWarning()}
        />
      }
      {(currentInstance?.is_allow_edit || !isEditing) &&
        <div className={`actionButtons ${classes.actionButtons}`}>
          <div className={classes.validationMessage}>
            {getValidationWarning()}
          </div>
          <PopoverConfirm
            title={t('warnings.cancelation')}
            onConfirm={() => activeForm === 'instance' ? navigate('/instances') : goToInstanceForm()}
            hasChanges={hasInstanceChanged || hasTopicChanged}
          >
            <Button>
              {t('actions.cancel')}
            </Button>
          </PopoverConfirm>
          {(hasInstanceChanged || hasTopicChanged) &&
            <Button
              type='primary'
              loading={isSaving}
              onClick={onSubmit}
              disabled={!!getValidationWarning()?.length}
            >
              {activeForm === 'instance' ? t('actions.save') : t('actions.confirm')}
            </Button>
          }
        </div>
      }
    </div>
  )
}

const InstanceFormBlock: React.FC<InstanceFormBlockPropTypes> = ({
  setFormValues,
  formValues,
  isEditing,
  goToTopicForm,
  onSubmit,
  hasInstanceChanged,
  setHasInstanceChanged,
  hasTopicChanged,
  setHasTopicChanged
}) => {
  const { t } = useTranslation(['pages', 'app', 'forms'])
  const currentInstance = useAppSelector(selectCurrentInstance)
  const dispatch = useAppDispatch()
  const isBackLinkConfirmModalShown = useAppSelector(selectBackLinkModalOpened)
  const navigate = useNavigate()

  useEffect(() => {
    if (isEditing && currentInstance?.id && !formValues?.instance?.name && formValues?.instance?.color === defaultColor) {
      setFormValues({
        ...formValues,
        instance: {
          name: currentInstance.name,
          description: currentInstance.description,
          color: currentInstance.color || defaultColor,
          order_num: currentInstance.order_num
        }
      })
    }
  }, [formValues, currentInstance, isEditing, setFormValues])

  const deleteTopic = (topicId: number, tempId: number) => {
    setHasInstanceChanged(true)
    if (topicId === 0) {
      const deletedOrderNum = formValues.addedTopics.find(t => t.temp_id === tempId)?.order_num
      setFormValues({
        ...formValues,
        addedTopics: formValues.addedTopics
          .filter(t => t.temp_id !== tempId)
          .map(t => t?.order_num! > deletedOrderNum! ? { ...t, order_num: t.order_num! - 1 } : t),
        addedSubtopics: formValues.addedSubtopics.filter(s => s.temp_topic_id !== tempId)
      })
    } else {
      setFormValues({
        ...formValues,
        deletedTopics: [...formValues.deletedTopics, topicId],
        addedSubtopics: formValues.addedSubtopics.filter(s => s.topic_id !== topicId),
        editedSubtopics: formValues.editedSubtopics.filter(s => s.topic_id !== topicId),
      })
    }
  }

  const handleChange = (updatedValues: InstanceFormType) => {
    setHasInstanceChanged(true)
    setFormValues(updatedValues)
  }

  const onCancelChangesSave = () => {
    setHasInstanceChanged(false)
    setHasTopicChanged(false)
    dispatch(setBackLinkModalOpened(false))
    navigate('/instances')

  }
  return (
    <>
      <GoBackLink
        link='/instances'
        text={t('instanceDetails.backBtn')}
        showConfirmModal={hasInstanceChanged || hasTopicChanged ? () => dispatch(setBackLinkModalOpened(true)) : undefined}
      />
      <div className={classes.pageTitle}>
        <h1 className='pageTitle'>
          {isEditing ? currentInstance?.name : t('instanceDetails.title')}
        </h1>
      </div>

      <div className={classes.formWrapper}>
        <h2>
          {t('instanceDetails.subtitle')}
        </h2>
        <h3>
          {isEditing ? t('instanceDetails.editDescription') : t('instanceDetails.addDescription')}
        </h3>
        <Divider style={{ margin: '20px 0px' }} />

        <TitleDescriptionFields
          title={formValues.instance.name}
          onTitleChange={(val: string) => handleChange({
            ...formValues,
            instance: { ...formValues.instance, name: val }
          })}
          titleLabel={'instance'}
          description={formValues.instance.description}
          onDescriptionChange={(val: string) => handleChange({
            ...formValues,
            instance: { ...formValues.instance, description: val }
          })}
        />

        <div className={classes.formBlock}>
          <div className={classes.label}>
            {t('fields.instanceColor.label', {ns: 'forms'})}
          </div>
          <div className={classes.inputWrapper}>
            <ColorPicker
              format='hex'
              value={formValues.instance.color}
              onChange={val => handleChange({
                ...formValues,
                instance: { ...formValues.instance, color: val.toHexString() }
              })}
            />
            <span style={{ marginLeft: '10px' }}>
              {formValues.instance.name?.length
                ? (
                  <Tag
                    style={{ backgroundColor: formValues.instance.color, color: '#3B414B' }}
                    text={formValues.instance.name}
                  />
                ) : (
                  t('fields.instanceColor.placeholder', {ns: 'forms'})
                )
              }
            </span>
          </div>
        </div>
        <h2>
          {t('instanceDetails.topic.subtitle')}
        </h2>
        <h3>
          {isEditing ? t('instanceDetails.topic.editDescription') : t('instanceDetails.topic.addDescription')}
        </h3>
        <Divider style={{ margin: '20px 0px' }} />
        <div className={classes.topicList}>
          {sortBy(currentInstance?.topics, (topic) => topic?.order_num)
            ?.filter(t => !formValues.deletedTopics.includes(t.id))
            ?.map(topic => {
              const topicData = formValues?.editedTopics?.find(t => t.id === topic.id) || topic
              return (
                <TopicCard
                  topic={topicData}
                  onDeleteTopic={deleteTopic}
                  key={topicData.id}
                  onClick={(topic: NewInstanceTopicType) => goToTopicForm(topic)}
                  allowEdit={currentInstance?.is_allow_edit || !isEditing}
                />
              )
            })
          }
          {formValues?.addedTopics.map(topic => (
            <TopicCard
              topic={topic}
              isNew
              onDeleteTopic={deleteTopic}
              key={topic?.temp_id}
              onClick={(topic: NewInstanceTopicType) => goToTopicForm(topic)}
              allowEdit={currentInstance?.is_allow_edit || !isEditing}
            />
          ))}
        </div>
        {(currentInstance?.is_allow_edit || !isEditing) &&
          <div className={classes.addNewBtn} onClick={() => goToTopicForm()}>
            + {t('instanceDetails.topic.addTopicBtn')}
          </div>
        }
      </div>
      <ConfirmationModal
        open={!!isBackLinkConfirmModalShown}
        title={t('warnings.unsavedChanges.title', {ns: 'app'})}
        description={t('warnings.unsavedChanges.description', {ns: 'app'})}
        onClose={onCancelChangesSave}
        // @ts-ignore
        onConfirm={onSubmit}
        type='warning'
        confirmText={t('actions.save', {ns: 'app'})}
      />
    </>
  )
}

interface InstanceFormBlockPropTypes {
  formValues: InstanceFormType
  setFormValues: (values: InstanceFormType) => void
  isEditing: boolean
  goToTopicForm: (topic?: NewInstanceTopicType | InstanceTopicType) => void
  onSubmit: () => void
  hasInstanceChanged: boolean
  setHasInstanceChanged: (hasChanged: boolean) => void
  setHasTopicChanged: (hasChanged: boolean) => void
  hasTopicChanged: boolean
}

interface SelectedTopicType extends NewInstanceTopicType {
  subtopics: NewInstanceSubtopicType[]
  subtopicsDeleted?: number[],
  subtopicsDeletedWithTempId?: number[],
  subtopicsEdited?: NewInstanceSubtopicType[],
  subtopicsAdded?: NewInstanceSubtopicType[]
  isEditing: boolean
}

export default InstanceForm
