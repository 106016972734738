import { pickBy } from 'lodash'
import { Button, Checkbox, Spin } from 'antd'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { UpdateUserReportSettingsThunk, selectReportSettings, selectUserData } from '../../../../store/currentUserReducer'
import { selectInstanceList } from '../../../../store/instanceReducer'
import { selectSelectedPersonData, setPersonDetailsActiveTabKey } from '../../../../store/personReducer'
import { NewRequestDataType, ReportCDType, ReportTemplateType, RequestDataType } from '../../../../types/requestTypes'
import AdditionalInformation from './AdditionalInformation/AdditionalInformation'
import classes from './ReportSettings.module.css'
import commonClasses from './../GenerateReport.module.css'
import { useState } from 'react'
import { CreateRequestThunk, EditRequestThunk, MakePromptThunk, SaveRequestResultThunk, selectRequestData, setRequestData as setStateRequestData  } from '../../../../store/requestReducer'
import { EditUserThunk, getUserGeneralData } from '../../../../store/userReducer'
import PopoverConfirm from '../../../common/PopoverConfirm/PopoverConfirm'
import { useNavigate } from 'react-router-dom'
import ConfirmationModal from '../../../common/ConfirmationModal/ConfirmationModal'
import Instances from './Instances/Instances'
import TooltipHint from '../../../common/TooltipHint/TooltipHint'
import { checkInstancesForConflicts, getAvailableReportTypes, getReportTypes } from '../../../../helpers/generateReportHelper'
import { ReactComponent as WarningIcon } from './../../../../img/icons/warning.svg'
import { InstanceType } from '../../../../types/instanceType'
import { useTranslation } from 'react-i18next'

const ReportSettings:React.FC<ReportSettingsPropTypes> = ({
  setRequestData,
  requestData,
  settingTemplate,
  sessionSelectionType,
  isGenerating,
  setIsGenerating,
  isInstanceDataLoading,
  getPersonId,
  setResultsActiveTabKey,
  selectedReportTypes,
  setSelectedReportTypes,
  setSettingTemplate,
  activeReportType,
  setActiveReportType,
  getSelectedSessionsInstances,
  setHasChanges,
  hasChanges
}) => {
  const { t } = useTranslation(['app'])
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const instanceList = useAppSelector(selectInstanceList)
  const selectedPersonData = useAppSelector(selectSelectedPersonData)
  const userReportSettings = useAppSelector(selectReportSettings)
  const generatedRequestData = useAppSelector(selectRequestData)
  const userData = useAppSelector(selectUserData)

  const [copySettings, setCopySettings] = useState({user: false, person: false})
  const [regenerateConfirmationOpen, setRegenerateConfirmationOpen] = useState<false | ReportCDType[]>(false)

  const validInstanceList = instanceList?.filter(i => requestData?.instance_list?.includes(i.id) && !!getSelectedSessionsInstances()?.some(instance => instance.id === i.id))

  const onSelectedReportTypesChange = (types: ReportCDType[]) => {
    setHasChanges(true)
    let updatedTypes = types
    if ((types.includes('literature') || types.includes('keywords')) && !types.includes('main') && !getReportContent('main')) {
      updatedTypes = [...updatedTypes, 'main']
    }
    if (types.includes('kernberg') && !types.includes('diagnoses') && !getReportContent('diagnoses')) {
      updatedTypes = [...updatedTypes, 'diagnoses']
    }
    // if (!validInstanceList?.length) {
    //   updatedTypes.filter(t => t !== 'main' && t !== 'literature' && t !== 'keywords')
    // }
    setSelectedReportTypes(updatedTypes)
  }

  const selectActiveReportType = (type: ReportCDType) => {
    setHasChanges(true)
    setActiveReportType(type)
  }

  const getReportContent = (reportType: ReportCDType) => {
    return generatedRequestData?.request_reports.find(r => r.rep_cd === reportType)?.chat_gpt_completion
  }

  const getFormattedRequestData = () => {
    let request: NewRequestDataType
    const availableInstances = instanceList?.filter(i => requestData?.instance_list?.includes(i.id) && !!getSelectedSessionsInstances()?.some(instance => instance.id === i.id))
    if (sessionSelectionType === 'period') {
      request = pickBy(requestData, (_, key) => {
        if (sessionSelectionType === 'period') {
          return key !== 'session_list'
        }
      }) as NewRequestDataType
    } else {
      request = requestData!
    }
    if ((activeReportType === 'diagnoses' || activeReportType === 'kernberg')) {
      const requiredInstance = getSelectedSessionsInstances()?.find(i => i.name.toLowerCase() === 'symptoms')
      if (!!requiredInstance?.id && (!requestData?.instance_list?.length || !(requestData?.instance_list?.length === 1 && requestData?.instance_list?.includes(requiredInstance?.id)))) {
        request.instance_list = [requiredInstance?.id!]
      }
    } else if (activeReportType === 'conflicts') {
      const conflictsReportInstancesData = checkInstancesForConflicts(getSelectedSessionsInstances())
      if (conflictsReportInstancesData?.isReportAvailable && (
        !requestData?.instance_list?.length
        ||
        !(requestData?.instance_list?.length === 4 && requestData?.instance_list.filter(iId => conflictsReportInstancesData?.availableRequiredInstances?.some(i => i.id === iId))?.length === 4)
      )) {
        request.instance_list = conflictsReportInstancesData.availableRequiredInstances.map(i => i.id)
      }
    } else {
      request.instance_list = availableInstances?.map(instance => instance.id) || []
    }
    return request
  }

  const getValidationErrorMessage = () => {
    if (sessionSelectionType === 'manual' && !requestData?.session_list?.length) {
      return 'Make sure you have selected at least one session'
    } else if (!requestData?.instance_list?.length) {
      return 'Make sure you have selected at least one instance'
    } else if (!requestData?.p_lang_cd) {
      return 'Make sure you have selected report language'
    } else if (!requestData?.report_style) {
      return 'Make sure you have selected report style'
    } else if (!requestData?.report_format) {
      return 'Make sure you have selected report format'
    }
  }

  const cancelCreating = () => {
    dispatch(setStateRequestData(null))
    dispatch(setPersonDetailsActiveTabKey('reports'))
    navigate(`/patients/edit/${getPersonId()}`)
  }

  const sendRequest = (request: NewRequestDataType) => {
    if (generatedRequestData?.request_id) {
      return dispatch(EditRequestThunk({id: generatedRequestData?.request_id, requestData: request}))
    } else {
      return dispatch(CreateRequestThunk(request))
    }
  }

  const updateReportSettings = async(requestId: number) => {
    setHasChanges(true)
    const newSettings = {
      lang_cd: requestData?.p_lang_cd!,
      report_style: requestData?.report_style!,
      report_format: requestData?.report_format!,
      instance_list: requestData?.instance_list!,
    }
    if (copySettings?.user && !userReportSettings?.lang_cd) {
      const updatedUserData = {
        ...getUserGeneralData(userData),
        ...newSettings,
      }
      dispatch(EditUserThunk({
        userId: userData.user_id,
        userData: updatedUserData,
      }))
    }
    dispatch(UpdateUserReportSettingsThunk({
      settingsIdData: {
        from_report_id: requestId,
        ...(copySettings?.person ? {to_person_id: selectedPersonData?.id} : {}),
        ...(copySettings?.user && !!userReportSettings?.lang_cd ? {to_user_id: userData?.user_id} : {}),
      },
      settings: {...newSettings, instance_list: getSelectedSessionsInstances().filter(i => newSettings.instance_list.includes(i.id))}
    }))
  }

  const makePrompt = async(requestId: number, reportType: ReportCDType) => {
    return dispatch(MakePromptThunk({requestId: requestId, reportType: reportType}))
  }

  const handleMultipleRequestChange = async(requestId: number) => {
    setHasChanges(true)
    if (copySettings?.person || copySettings?.user) {
      updateReportSettings(requestId)
    }
    const reports = await Promise.all(selectedReportTypes
      .filter(type => getReportTypes().independent.some(iType => iType === type))
      .map((type: ReportCDType) => makePrompt(requestId, type))
    )
    if (selectedReportTypes.includes('literature') || selectedReportTypes.includes('keywords') || selectedReportTypes.includes('kernberg')) {
      const allReportsIndex = reports?.reduce((p, c, i, a) => (a[p]?.payload as RequestDataType)?.request_reports?.length > (c?.payload as RequestDataType)?.request_reports?.length ? p : i, 0)
      const generatedReportData = reports?.length ? (reports?.[allReportsIndex]?.payload as RequestDataType)?.request_reports : generatedRequestData?.request_reports
      await dispatch(SaveRequestResultThunk({requestId: requestId, reportData: generatedReportData?.map(r => ({message: r.chat_gpt_completion, rep_cd: r.rep_cd as ReportCDType})) || []}))
      await Promise.all(selectedReportTypes
        .filter(type => getReportTypes().dependent.some(iType => iType === type))
        .map((type: ReportCDType) => makePrompt(requestId, type))
      )
    }
    setIsGenerating([])
    setResultsActiveTabKey(selectedReportTypes[0])
    document?.getElementById('reportResult')?.scrollIntoView()
  }

  const createRequest = () => {
    const requestDataFormatted = getFormattedRequestData()
    setIsGenerating(selectedReportTypes)
    setRegenerateConfirmationOpen(false)
    if (!generatedRequestData?.request_id || selectedReportTypes.includes('main')) {
      return sendRequest(requestDataFormatted)
        .then((resp) => {
          if (resp.type.includes('rejected')) {
            setIsGenerating([])
            return resp as any
          } else {
            return handleMultipleRequestChange((resp.payload as RequestDataType)?.request_id)
          }
        })
    } else {
      return handleMultipleRequestChange(generatedRequestData?.request_id)
    }
  }

  const onSubmit = () => {
    const hasGeneratedResult = selectedReportTypes?.filter(t => generatedRequestData?.request_reports?.some(report => report.chat_gpt_completion?.length && report.rep_cd === t))
    if (hasGeneratedResult?.length) {
      setRegenerateConfirmationOpen(hasGeneratedResult)
    } else {
      createRequest()
    }
  }

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.blockWrapper}>
          <h2 style={{fontSize: '24px', fontWeight: 600, marginTop: '0px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            Reports
            <div
              className={commonClasses.selectAll}
              onClick={() => setSelectedReportTypes(selectedReportTypes?.length === getAvailableReportTypes(getSelectedSessionsInstances())?.length
                ? []
                : getAvailableReportTypes(getSelectedSessionsInstances())
              )}
            >
              {selectedReportTypes?.length === getAvailableReportTypes(getSelectedSessionsInstances())?.length
                ? 'Deselect all'
                : 'Select all'
              }
            </div>
          </h2>
          <h3>
            Select the type of report you want to generate
          </h3>
          <Checkbox.Group
            onChange={checkedList => onSelectedReportTypesChange(checkedList as ReportCDType[])}
            value={selectedReportTypes}
            rootClassName={classes.reportTypeOptions}
            disabled={!!isGenerating?.length}
          >
            <ReportTypeOptionBtn
              value='main'
              // selected={selectedReportTypes.includes('main')}
              active={activeReportType === 'main'}
              name={'General'}
              description={'Generating a report based on patient data'}
              loading={isGenerating.includes('main')}
              onClick={selectActiveReportType}
              getSelectedSessionsInstances={getSelectedSessionsInstances}
            />

            <ReportTypeOptionBtn
              value='conflicts'
              // selected={selectedReportTypes.includes('conflicts')}
              active={activeReportType === 'conflicts'}
              name={'Conflicts'}
              description={'Generating a report based on instances'}
              loading={isGenerating.includes('conflicts')}
              onClick={selectActiveReportType}
              unavailable={!checkInstancesForConflicts(getSelectedSessionsInstances())?.isReportAvailable}
              getSelectedSessionsInstances={getSelectedSessionsInstances}
            />

            <ReportTypeOptionBtn
              value='diagnoses'
              // selected={selectedReportTypes.includes('diagnoses')}
              active={activeReportType === 'diagnoses'}
              name={'Diagnoses'}
              description={'Generation based on ICD-10 and ICD-11'}
              loading={isGenerating.includes('diagnoses')}
              onClick={selectActiveReportType}
              unavailable={!getSelectedSessionsInstances()?.some(i => i.name.toLowerCase() === 'symptoms')}
              getSelectedSessionsInstances={getSelectedSessionsInstances}
            />

            <ReportTypeOptionBtn
              value='kernberg'
              // selected={selectedReportTypes.includes('kernberg')}
              active={activeReportType === 'kernberg'}
              name={'Diagnoses (Kernberg)'}
              description={'Available after generation of the Diagnosis'}
              // disabled={!getReportContent('diagnoses')}
              loading={isGenerating.includes('kernberg')}
              onClick={selectActiveReportType}
              unavailable={!getSelectedSessionsInstances()?.some(i => i.name.toLowerCase() === 'symptoms')}
              getSelectedSessionsInstances={getSelectedSessionsInstances}
            />

            <ReportTypeOptionBtn
              value='literature'
              // selected={selectedReportTypes.includes('literature')}
              active={activeReportType === 'literature'}
              name={'Literature'}
              description={'Available after generation of the general report'}
              // disabled={!getReportContent('main')}
              loading={isGenerating.includes('literature')}
              onClick={selectActiveReportType}
              getSelectedSessionsInstances={getSelectedSessionsInstances}
            />

            <ReportTypeOptionBtn
              value='keywords'
              // selected={selectedReportTypes.includes('keywords')}
              active={activeReportType === 'keywords'}
              name={'Keywords'}
              description={'Available after generation of the general report'}
              // disabled={!getReportContent('main')}
              loading={isGenerating.includes('keywords')}
              onClick={selectActiveReportType}
              getSelectedSessionsInstances={getSelectedSessionsInstances}
            />
          </Checkbox.Group>
        </div>

        <div className={classes.blockWrapper}>
          <Instances
            isGenerating={isGenerating}
            settingTemplate={settingTemplate}
            requestData={requestData}
            setRequestData={setRequestData}
            validInstanceList={validInstanceList}
            getSelectedSessionsInstances={getSelectedSessionsInstances}
            sessionSelectionType={sessionSelectionType}
            isInstanceDataLoading={isInstanceDataLoading}
            setSettingTemplate={setSettingTemplate}
            activeReportType={activeReportType}
            setHasChanges={setHasChanges}
          />

          <AdditionalInformation
            setRequestData={setRequestData}
            requestData={requestData}
            settingTemplate={settingTemplate}
            disabled={false}
            isGenerating={isGenerating}
            copySettings={copySettings}
            setCopySettings={setCopySettings}
            activeReportType={activeReportType}
          />
        </div>
      </div>
      <div className={`actionButtons ${classes.actionButtons}`}>
        <div className={classes.warning}>
          {getValidationErrorMessage()}
        </div>
        <PopoverConfirm
          title={t('warnings.cancelation')}
          onConfirm={() => cancelCreating()}
          hasChanges={hasChanges}
        >
          <Button style={hasChanges? {} : {marginRight: '10px'}}>
            Cancel
          </Button>
        </PopoverConfirm>
        <Button
          type='primary'
          onClick={() => onSubmit()}
          disabled={
            !!isGenerating?.length
            || !requestData?.p_lang_cd
            || !requestData?.report_style
            || !requestData?.report_format
            || (sessionSelectionType === 'manual' && !requestData?.session_list?.length)
            || !selectedReportTypes?.length
            || ((selectedReportTypes.includes('main') || selectedReportTypes.includes('keywords') || selectedReportTypes.includes('literature')) && !validInstanceList?.length && !selectedReportTypes.includes('diagnoses') && !selectedReportTypes.includes('kernberg') && !selectedReportTypes.includes('conflicts'))
            // || ((selectedReportTypes.includes('diagnoses') || selectedReportTypes.includes('kernberg')) && !getSelectedSessionsInstances().some(i => i.name.toLowerCase() === 'symptoms'))
            // || (selectedReportTypes.includes('conflicts') && !checkInstancesForConflicts(getSelectedSessionsInstances())?.isReportAvailable)
          }
          loading={!!isGenerating?.length}
          className={`
            ${classes.generateBtn}
            ${!requestData?.instance_list?.length || (sessionSelectionType === 'manual' && !requestData?.session_list?.length) ? classes.disabled : ''}
            ${selectedReportTypes?.some(t => generatedRequestData?.request_reports?.some(report => report.chat_gpt_completion?.length && report.rep_cd === t)) ? classes.repeatGeneration : ''}
          `}
        >
          Generate
        </Button>
      </div>
      <ConfirmationModal
        open={regenerateConfirmationOpen !== false && !!regenerateConfirmationOpen?.length}
        title={`Generate new ${regenerateConfirmationOpen !== false && regenerateConfirmationOpen?.length === 1 ? 'report' : 'reports'}`}//${regenerateConfirmationOpen === 'main' ? 'general report' : regenerateConfirmationOpen}`}
        description={regenerateConfirmationOpen === false
          ? ''
          : `Are you sure you want to generate new ${regenerateConfirmationOpen?.join(', ')?.replace('main', 'general')} ${regenerateConfirmationOpen?.length === 1 ? 'report' : 'reports'}? The previous results ${regenerateConfirmationOpen?.includes('main') || regenerateConfirmationOpen?.includes('diagnoses') ? '(and all dependent reports)' : ''} will not be saved`}
        onClose={() => setRegenerateConfirmationOpen(false)}
        onConfirm={() => createRequest()}
        type='warning'
        confirmText='Generate'
        isRequestLoading={!!isGenerating.length}
      />
    </>
  )
}

const ReportTypeOptionBtn: React.FC<{
  value: ReportCDType,
  // selected: boolean,
  active: boolean,
  // disabled?: boolean,
  unavailable?: boolean,
  name: string,
  description: string,
  loading: boolean,
  onClick: (reportType: ReportCDType) => void,
  getSelectedSessionsInstances: () => InstanceType[]
}> = ({value, active, unavailable, name, description, loading, onClick, getSelectedSessionsInstances}) => {
  // const isDisabled = disabled || loading
  const isDisabled = loading

  return (
    <div
      className={`
        ${classes.reportTypeOption}
        ${active ? classes.active : ''}
        ${isDisabled ? classes.disabled : ''}
        ${unavailable ? classes.unavailable : ''}
      `}
      onClick={isDisabled ? () => {} : () => { onClick(value)}}
    >
      <div className={classes.reportTypeData}>
        {unavailable &&
          <TooltipHint
            title={name === 'Conflicts'
              ? `No data in the required instances ${'('+checkInstancesForConflicts(getSelectedSessionsInstances()).notAvailableRequiredInstances.join(', ')+')'}, select another report type or fill in patient data to generate this report.`
              : 'No data in the “Symptoms” instance, select another report type or fill in patient data to generate this report.'
            }
          >
            <WarningIcon className={classes.unavailableIcon}/>
          </TooltipHint>
        }
        <div>
          <div className={commonClasses.name} style={{color: '#111827'}}>
            <Checkbox
              value={value}
              style={{marginBottom: '8px', marginRight: '12px'}}
              disabled={unavailable || loading}
              onClick={e => e.stopPropagation()}
            />
            {name}
          </div>
          <div className={commonClasses.description} style={{marginBottom: '0px'}}>
            {description}
          </div>
        </div>
        {!!loading &&
          <TooltipHint title='Generating report...'>
            <div>
              <Spin />
            </div>
          </TooltipHint>
        }
      </div>
    </div>
  )
}

interface ReportSettingsPropTypes {
  setRequestData: (requestData: NewRequestDataType | null) => void
  requestData: NewRequestDataType | null
  settingTemplate: ReportTemplateType
  sessionSelectionType: 'period' | 'manual'
  isGenerating: ReportCDType[]
  setIsGenerating: (val: ReportCDType[]) => void
  isInstanceDataLoading: boolean
  getPersonId: () => number
  setResultsActiveTabKey: (val: ReportCDType) => void
  selectedReportTypes: ReportCDType[]
  setSelectedReportTypes: (val: ReportCDType[]) => void
  setSettingTemplate: (template: ReportTemplateType) => void
  activeReportType: ReportCDType
  setActiveReportType: (val: ReportCDType) => void
  getSelectedSessionsInstances: () => InstanceType[]
  setHasChanges: (val: boolean) => void
  hasChanges: boolean
}

export default ReportSettings
